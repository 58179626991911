<template>
  <div class="container-fluid h-100 pl-0 pr-0">
    <div class="jumbotron jumbotron-fluid bg-primary mb-0">
      <div class="container">
        <h1 class="display-4 text-white" title="Navin Chandrasekaran">
          <div class="d-none d-sm-block">Navin Chandrasekaran</div>
          <div class="d-block d-sm-none">Navin C</div>
        </h1>
        <p class="lead text-white">
          Full Stack Sr. Software
          Developer/Architect
          | .Net | Vue | MS SQL & more...
        </p>
      </div>
    </div>
    <Home />
  </div>
</template>
<script>
import Home from "../components/Home"
export default {
  components: {
    Home,
  },
}
</script>
<style lang="scss" scoped>
</style>  