import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import 'bootstrap'
import router from './router'
import store from './store'
import { auth } from './firebase'

Vue.config.productionTip = false
/**
 * Uncomment below when compiling to production
 */
// Vue.config.devtools = false
// Vue.config.debug = false
// Vue.config.silent = true

/**
 * Uncomment below when compiling to production
 */
import './assets/stylesheets/custom.scss'

let app
auth.onAuthStateChanged(user => {
  if (!app) {
    app = new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  }

  if (user) {
    store.dispatch('fetchUserProfile', user)
  }
})
