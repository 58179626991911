<template>
  <div id="app">
    <Navbar v-if="showNav"></Navbar>
    <router-view />
  </div>
</template>
<script>
import { mapState } from "vuex"
import Navbar from "@/components/Navbar"

export default {
  components: {
    Navbar,
  },
  computed: {
    ...mapState(["userProfile"]),
    showNav() {
      return Object.keys(this.userProfile).length > 1
    },
  },
}
</script>
<style lang="scss" scoped>
#app {
  height: 100vh;
}
</style>