import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCu8bITpZ4KqeZApAqJO8xu7DnNv68NM_I",
    authDomain: "navin-f02db.firebaseapp.com",
    databaseURL: "https://navin-f02db.firebaseio.com",
    projectId: "navin-f02db",
    storageBucket: "navin-f02db.appspot.com",
    messagingSenderId: "1089644418713",
    appId: "1:1089644418713:web:7ae25bda6ba0899d104a08",
    measurementId: "G-9QBM9EKC1Y"
}
// Initialize Firebase
firebase.initializeApp(firebaseConfig)

// utils
const db = firebase.firestore()
const auth = firebase.auth()

// collection references
const usersCollection = db.collection('users')
const postsCollection = db.collection('posts')
const commentsCollection = db.collection('comments')
const likesCollection = db.collection('likes')

// export utils/refs
export {
    db,
    auth,
    usersCollection,
    postsCollection,
    commentsCollection,
    likesCollection
}
