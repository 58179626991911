<template>
  <!-- Image and text -->
  <nav class="navbar navbar-light bg-light shadow">
    <a class="navbar-brand" href="/">
      <img
        src="../../public/img/icons/favicon-32x32.png"
        width="30"
        height="30"
        class="d-inline-block align-top"
        alt
        loading="lazy"
      />
    </a>
    <button class="btn btn-light btn-sm my-2 my-sm-0" type="button" v-on:click="logout()">Sign Off</button>
  </nav>
</template>

<script>
export default {
  methods: {
    logout() {
      this.$store.dispatch("logout")
    },
  },
}
</script>